import Home from '@/components/view/Home'
import AboutUs from '@/components/view/AboutUs'
import InmueblesView from '@/components/view/inmueble/InmueblesView'
import InmueblesStore from '@/components/view/inmueble/InmueblesStore'
import InmuebleDetail from '@/components/view/inmueble/InmuebleDetail'
import BlogsView from '@/components/view/blog/BlogsView'
import BlogArchive from '@/components/view/blog/BlogArchive'
import BlogDetail from '@/components/view/blog/BlogDetail'

const routes = [
    {
      path: '/',
      name: 'inicio',
      text: 'Inicio',
      component: Home
    },
    {
      path: '/inmuebles/',
      text: 'Inmuebles',
      name: 'inmuebles',
      redirect: { name: 'catalogo-inmuebles' },
      component: InmueblesView,
      children:[
        {
          path: 'catalogo/',
          name: 'catalogo-inmuebles',
          component: InmueblesStore
        },
        {
          path: 'detalle/:slug',
          name: 'inmuebles-detalle',
          component: InmuebleDetail
        }
      ]
    },
    {
      path: '/blog/',
      name: 'blog',
      text: 'Blog',
      redirect: { name: 'blog-articulos' },
      component: BlogsView,
      children:[
        {
          path: 'articulos/',
          name: 'blog-articulos',
          component: BlogArchive
        },
        {
          path: 'detalle/:slug',
          name: 'blog-detalle',
          component: BlogDetail
        }
      ]
    },
    {
      path: '/sobre-nosotros/',
      name: 'sobre-nosotros',
      text: 'Sobre nosotros',
      component: AboutUs
    },
    {
      path: '/',
      hash: '#contacto',
      name: 'contacto',
      text: 'Contacto',
      component: Home
    },
  ]
export {routes}