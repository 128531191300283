<template>
	<div id="SliderInmuebleDetail">
		<div class="slider" ref="slider">
			<div 
			v-for="(item, i) in items" 
			:key="i" 
			class="itemsSlider carousel__slide"
			:data-src="imgStorageURL(item.url)"
			data-fancybox="gallery"
			>
			<figure class="imageSlider">
				<img :src="imgStorageURL(item.url)" alt="">
			</figure>
		</div>
	</div>
	<div class="sliderThumbnails" ref="sliderThumbnails">
		<div 
		v-for="(item, i) in items" 
		:key="i" 
		class="itemsSlider carousel__slide"
		:data-src="item"
		data-fancybox="gallery"
		>
		<figure class="imageSlider">
			<img :src="imgStorageURL(item.url)" alt="">
		</figure>
	</div>
</div>
</div>
</template>

<script>
	import { Carousel } from "@fancyapps/ui"
	import "@fancyapps/ui/dist/fancybox.css"
	import { imgStorageURL } from "@/core/helpers";
	export default {

		name: 'Slider',
		props: {
			items: {
				type: Array
			}
		},
		data () {
			return {
				imgStorageURL
			}
		},
		created(){

		},
		mounted(){
			this.initSlider();
		},
		methods:{
			async initSlider(){
				const mainCarousel = await new Carousel(this.$refs['slider'], {
					Dots: false,
				});
				await new Carousel(this.$refs['sliderThumbnails'], {
					Sync: {
						target: mainCarousel,
						friction: 0,
					},
					Dots: false,
					Navigation: false,
					center: true,
					slidesPerPage: 1,
					infinite: false,
				});
			},
		}
	}
</script>

<style lang="css" >
	#SliderInmuebleDetail .slider {
		position: relative;
		margin:0 0 0.25rem;
		--carousel-button-color: #170724;
		--carousel-button-bg: #fff;
		--carousel-button-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%),
		0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);

		--carousel-button-svg-width: 20px;
		--carousel-button-svg-height: 20px;
		--carousel-button-svg-stroke-width: 2.5;
	}

	#SliderInmuebleDetail .carousel__slide .imageSlider{
		position: relative;
		padding-top: 50%;
		overflow: hidden;
	}
	
	#SliderInmuebleDetail .carousel__slide .imageSlider img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		top: 0;
		left:0;
	}
	#SliderInmuebleDetail .carousel__nav {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		inset: 0 -1.25rem;
		pointer-events: none;
	}
	#SliderInmuebleDetail .slider .carousel__slide {
		width: 100%;
		padding: 0;
	}

	#SliderInmuebleDetail .slider .carousel__button {
		position: static;
		transform: none;
	}
	#SliderInmuebleDetail .slider .carousel__button:focus {
		outline: none;
		box-shadow: 0 0 0 4px var(--tm-color);
	}

	#SliderInmuebleDetail .sliderThumbnails .carousel__slide {
		opacity: 0.5;
		padding: 0.25rem;
		width: 96px;
	}
	#SliderInmuebleDetail .sliderThumbnails .carousel__slide .imageSlider{
		padding-top: 60%;
	}
	#SliderInmuebleDetail .sliderThumbnails .carousel__slide .imageSlider img {
		border-radius: 4px;
	}

	#SliderInmuebleDetail .sliderThumbnails .carousel__slide.is-nav-selected {
		opacity: 1;
	}
</style>