import { baseURL } from "@/config.js";
export const imgStorageURL = (img)=>{
  let image = img;
  if(image && img.indexOf('http')){
    image = baseURL +'storage/'+ img;
  }
  return image
}

export const formtDate = (value) => {
  let date = new Date(value);
  let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Deciembre"];
  return {
    'año': date.getFullYear(),
    'mes': meses[date.getMonth()],
    'dia': date.getDate(),
  }
}