export default {
	computed:{
		inmuebles(){        
      return this.$store.state.AppInmuebles.grid.data;
    },
    inmueblesData(){        
      return this.$store.state.AppInmuebles.grid;
    },
    inmueblesList(){        
      return this.$store.state.AppInmuebles.list;
    }
	}
}