<template>
  <div id="Home">
    <Banner/>
    <Inmueble/>
    <Blog/>
    <Contact/>
  </div>
</template>
<script>
import Banner from "@/components/view/banner/Banner";
import Inmueble from "@/components/view/inmueble/Inmueble";
import Blog from "@/components/view/blog/Blog";
import Contact from "@/components/view/form/Contact";
export default {
  name: 'Home',
  components: {
    Banner,
    Inmueble,
    Blog,
    Contact
  },
}
</script>