<template>
	<div id="InmueblesStore">
		<router-view />
		<HeaderPage title="Inmuebles" />
		<div class="container-fluid xs md xxl section-page">
			<div class="row">
				<div id="panelSideBar" class="col-3 d-flex flex-column" :class="{'open': openFiltro}">
					<SideBar>
						<div class="widget-container">
							<div class="widget">
								<h3 class="title-widget">
									Filtros
								</h3>
								<div class="content-widget">
									<Filtro v-model="inmueblesData.overlay" />
								</div>
							</div>
							<b-button @click="toggleFiltro" class="btn-filtros">
                             <img src="https://cdn-icons-png.flaticon.com/512/50/50849.png" width="40" srcset=""> Filtros
							</b-button>
						</div>
					</SideBar>
				</div>
				<div class="col-lg-9 col-md-12">
					<div class="row">
						<div class="col">
							<h2 class="sub-title">Propiedades</h2>
						</div>
					</div>
					<b-overlay :show="inmueblesData.overlay">
						<Pagination class="mx-n3" :data="inmueblesData" inmueble />
					</b-overlay>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderPage from '@/components/layout/HeaderPage'
	import Pagination from '@/components/layout/Pagination'
	import Filtro from '@/components/layout/Filter'
	import SideBar from '@/components/layout/SideBar'
	import mixinInmuebles from '@/libs/mixins/mixinInmuebles'

	export default {

		name: 'InmueblesStore',
		mixins: [mixinInmuebles],
		components: {
			HeaderPage,
			Pagination,
			Filtro,
			SideBar
		},
		data () {
			return {
				openFiltro: false,
			}
		},
		mounted(){
			this.$store.dispatch('AppInmuebles/getInmuebles');
		},
		methods:{
			toggleFiltro(){
				this.openFiltro = !this.openFiltro;
			},
		}
	}
</script>

<style lang="css">

	.sub-title, .title-widget {
		font-size: 26px;
		margin-bottom: 0;
		text-transform: uppercase;
		font-weight: 700;
	}
	.title-widget {
		margin-bottom: 15px;
	}
/*Pagination*/
.page-item {
	margin: 6px;
}

.pagination {
	margin: -6px;
}

#App .page-link {
	line-height: 1;
	border-radius: 4px;
	color: inherit;
	transition: all ease 0.4s;
}

#App .page-item.active .page-link {
	background-color: var(--tm-color);
	border-color: var(--tm-color);
	color: #fff;
}

.page-link:hover {
	color: var(--tm-color);
}
@media(max-width: 991px){
	#panelSideBar {
		width: 280px;
		max-width: none;
		position: fixed;
		z-index: 99;
		background: #fff;
		padding: 20px;
		box-shadow: 3px 3px 10px #00000026;
		height: calc(100vh - 90px);
		top: 90px;
		left: 0;
		transform: translateX(-100%);
		transition: transform 0.4s ease;
	}
	#panelSideBar.open{
		transform: translateX(0);
	}
	#panelSideBar .sideBar{
		overflow-y: auto;
		height: 100%;
	}
	#App #MainBody .btn.btn-filtros{

		position: absolute;
		left: 100%;
		border: 3px solid var(--tm-color);
		color: var(--tm-color);
		background: #fff;
		border-left: 0;
		border-radius: 0 0.25rem 0.25rem 0;
		font-size: 30px;
		padding: 2px;
		top: 75px;
        width: 90%;
	}
}
@media(max-width: 767px){
	#panelSideBar{
		top: 70px;
		height: calc(100vh - 70px);
	}
}
</style>
