<template>
	<section id="Blog" class="sectionTemplate">
		<div class="container-fluid xs md xxl">
			<div class="row">
				<div class="col">
					<h2 class="titleDefault align-items-center">Blog</h2>
				</div>
			</div>
			<div class="row mx-n3 justify-content-center">
				<div v-for="item in blogs.list" :key="item.id" class="col-12 col-md-6 col-lg-4 p-3 d-flex flex-column">
					<BlogItem :item="item"/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import BlogItem from "./BlogItem.vue"
	import mixinBlogs from '@/libs/mixins/mixinBlogs'
	export default {
		name: 'Blog',
		mixins: [mixinBlogs],
		components: {
			BlogItem
		}
	}
</script>